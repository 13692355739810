<template>
  <div id="escuela">
    <v-container grid-list-xl fluid>
      <header-title title="Escuelas"></header-title>
      <!-- <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            class="pa-0 ma-0"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
            class="pa-0 ma-0"
            label="Departamento *"
            :items="departamentos"
            v-model="departamento_id"
            item-text="descripcion"
            item-value="id"
            :rules="[(v) => !!v || 'El campo es requerido']"
            required
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-spacer></v-spacer>
          <v-btn class="pa-0 ma-0" color="primary" dark @click="showDialog()">
            <v-icon>mdi-plus</v-icon>Nuevo
          </v-btn>
        </v-col>
      </v-row> -->
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
        ></v-text-field>
        <v-select
          label="Departamento *"
          :items="departamentos"
          v-model="departamento_id"
          item-text="descripcion"
          item-value="id"
          :rules="[(v) => !!v || 'El campo es requerido']"
          @change="getInstituciones"
          required
        ></v-select>
        <v-spacer></v-spacer>
        <v-btn type="button" color="primary" dark to="/institucion/gestion">
          <v-icon>mdi-plus</v-icon>Nuevo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="instituciones"
        :loading="loading"
        :search="search"
        calculate-widths
        no-data-text="No existen registros"
        no-results-text="Sin resultados"
        item-key="name"
        class="elevation-1"
        :items-per-page="15"
        v-cloak
      >
        <!-- <template slot="item.nro" scope="props">
          {{ props.index + 1 }}
        </template> -->
        <template v-slot:item.fecha="{ item }">
          <td>
            {{ item.fecha | formatdate }}
          </td>
        </template>
        <template v-slot:item.estado="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:item.actions="{ item }">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="blue"
                  @click="showDetail(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-file-document</v-icon>
                </v-btn>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  dark
                  color="red"
                  @click="confirmItem(item.id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-close-box</v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-dialog v-model="idialog" scrollable max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Detalle de Institución</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list two-line>
            <h4 class="grey--text text--darken-1">{{ institucion.nombre }}</h4>
            <v-divider></v-divider>
            <v-list-item exact>
              <v-list-item-content>
                <v-list-item-title>Código</v-list-item-title>
                <v-list-item-subtitle>
                  {{ institucion.codigo }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Tipo Institución</v-list-item-title>
                <v-list-item-subtitle>
                  {{ institucion.tipo_institucion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title>Descripción</v-list-item-title>
                <v-list-item-subtitle>
                  {{ institucion.descripcion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Dirección</v-list-item-title>
                <v-list-item-subtitle>
                  {{ institucion.departamento.descripcion }},
                  {{ institucion.direccion }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click.native="idialog = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mconfirm" width="300">
      <v-card>
        <v-card-title class="headline grey lighten-3" primary-title>
          <span class="headline">Alerta</span>
        </v-card-title>
        <v-card-text>
          <p>¿Esta seguro(a) de eliminar el registro?</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :loading="btn_loading"
            text
            @click="deleteItem()"
            >Aceptar</v-btn
          >
          <v-btn color="red" text @click="mconfirm = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snack.state"
      top="top"
      right="right"
      rounded="pill"
      :color="snack.color"
      timeout="2500"
    >
      {{ snack.text }}
      <!-- <v-btn dark text @click="snack.state = false">Cerrar</v-btn> -->
    </v-snackbar>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Service from "../services/general";
import axios from "axios";
export default {
  name: "escuela",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      search: "",
      currentPage: 0,
      menu: false,
      loading: false,
      btn_loading: false,
      headers: [
        // { text: "Nro ", value: "nro", sortable: false },
        { text: "Código ", value: "codigo", sortable: false },
        { text: "Tipo ", value: "tipo_institucion" },
        { text: "Nombre ", value: "nombre" },
        { text: "Departamento ", value: "departamento.descripcion" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      departamentos: [],
      instituciones: [],
      institucion: {
        id: "",
        codigo: "",
        tipo_institucion: "",
        nombre: "",
        descripcion: "",
        codigo_univ: "",
        codigo_normal: "",
        codigo_ue: "",
        departamento_id: "",
        departamento: {},
        provincia_id: "",
        municipio_id: "",
        canton_id: "",
        localidad_id: "",
        direccion: "",
        correo: "",
        telefono: "",
        estado: "",
      },
      departamento_id: "A",
      institucion_id: "",
      idialog: false,
      mconfirm: false,
      snack: {
        state: false,
        color: "success",
        text: "",
      },
    };
  },
  created() {
    // this.serve = Service.getServe();
  },
  mounted() {
    if (Service.getUser()) {
      if (Service.getUser().role == 1) {
        this.getInstituciones();
        this.getDepartamentos();
      } else {
        this.$router.replace({ name: "dashboard" });
      }
    }
  },
  methods: {
    getInstituciones() {
      this.loading = true;
      axios
        .get(
          Service.getBase() + "instituciones/" + this.departamento_id,
          Service.getHeader()
        )
        .then((response) => {
          this.loading = false;
          this.instituciones = response.data;
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },

    getDepartamentos() {
      axios
        .get(Service.getBase() + "departamentos/activo", Service.getHeader())
        .then((response) => {
          response.data.unshift({ id: "A", descripcion: "Todos" });
          this.departamentos = response.data;
        })
        .catch((error) => {
          console.error("Error al cargar registros", error);
        });
    },

    showDetail(data) {
      this.institucion = data;
      this.idialog = true;
    },

    editItem(data) {
      this.institucion = data;
      this.mdialog = true;
    },

    confirmItem(id) {
      this.institucion.id = id;
      this.mconfirm = true;
    },

    deleteItem() {
      this.btn_loading = true;
      axios
        .delete(
          Service.getBase() + "institucion/" + this.institucion.id,
          Service.getHeader()
        )
        .then(() => {
          this.btn_loading = false;
          this.toast("success", "Registro eliminado");
          this.mconfirm = false;
          this.getInstituciones();
        })
        .catch(() => {
          this.btn_loading = false;
          this.toast("error", "Registro en uso o no existente");
        });
    },

    toast(mcolor, mtext) {
      this.snack.color = mcolor;
      this.snack.text = mtext;
      this.snack.state = true;
    },
  },
};
</script>
